<template>
  <div>
    <blacklist-table ref="blacklistTable" />
    <add-blacklist-modal @update-complete="refreshTable"></add-blacklist-modal>
    <edit-blacklist-modal
      :data="select_row"
      @update-complete="refreshTable"
    ></edit-blacklist-modal>
  </div>
</template>
<script>
import BlacklistTable from "./component/blacklist/BlacklistTable.vue";
import AddBlacklistModal from "./component/blacklist/AddBlacklistModal.vue";
import EditBlacklistModal from "./component/blacklist/EditBlacklistModal.vue";

export default {
  components: {
    BlacklistTable,
    AddBlacklistModal,
    EditBlacklistModal,
  },
  data() {
    return {
      select_row: {},
    };
  },
  methods: {
    refreshTable() {
      console.log("refreshTable");
      this.$refs.blacklistTable.load_data();
    },
    editModal(row) {
      this.select_row = row;
      this.$bvModal.show("modal-edit-blacklist");
    },
  },
};
</script>
