<template>
  <div>
    <b-modal
      id="modal-edit-blacklist"
      ref="modal-edit-blacklist"
      title="แก้ไขข้อมูล Black List"
      no-close-on-backdrop
      size="lg"
      hide-footer
      class="mb-5"
      @shown="loadEditData"
    >
      <b-form @submit="editBlacklist">
        <b-form-group
          v-for="(field, index) in formFields"
          :key="index"
          :label="field.label"
        >
          <b-form-input
            v-if="field.type === 'input'"
            v-model="form[field.model]"
            :placeholder="field.placeholder"
          />
          <b-form-textarea
            v-if="field.type === 'textarea'"
            v-model="form[field.model]"
          />
        </b-form-group>
        <hr />
        <b-button variant="success" @click="editBlacklist">
          แก้ไขข้อมูล
        </b-button>
        <div class="my-5"></div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BFormRadio,
    BAlert,
    BFormTextarea,
  },
  props: ["data"],
  data() {
    return {
      form: {},
      formFields: [
        {
          label: "ชื่อ",
          model: "first_name",
          placeholder: "ภาษาไทยเท่านั้น",
          type: "input",
        },
        {
          label: "นามสกุล",
          model: "last_name",
          placeholder: "ภาษาไทยเท่านั้น",
          type: "input",
        },
        {
          label: "ชื่อ EN",
          model: "first_name_en",
          placeholder: "ภาษาอังกฤษ",
          type: "input",
        },
        {
          label: "นามสกุล EN",
          model: "last_name_en",
          placeholder: "ภาษาอังกฤษ",
          type: "input",
        },
        { label: "เลขที่บัตรประชาชน", model: "card_id", type: "input" },
        { label: "เบอร์โทร", model: "phone", type: "input" },
        { label: "Email", model: "email", type: "input" },
        { label: "รายละเอียด", model: "detail", type: "textarea" },
      ],
    };
  },

  methods: {
    loadEditData() {
      this.$http({
        method: "GET",
        url: `/member/blacklist/${this.data._id}`,
      }).then((x) => {
        this.form = x.data.data;
      });
    },
    editBlacklist() {
      console.log("this.form", this.form);

      this.$http({
        method: "PUT",
        url: `/member/blacklist/${this.data._id}`,
        data: this.form,
      })
        .then((x) => {
          this.toast_success("แก้ไขข้อมูลแล้ว", "");
          this.$emit("update-complete");
          this.$bvModal.hide("modal-edit-blacklist");
        })
        .catch((error) => {
          if (error.response) {
            const { data } = error.response;
            const { message } = data; // Destructure the 'data' from the response
            console.log(`Message: ${message}`);
            this.toast_error("มีข้อผิดพลาด", message);
          } else {
            this.toast_error("มีข้อผิดพลาด", error.message);
          }
        });
    },
  },
};
</script>
