<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col text-right">
          <b-button
            class="mb-1"
            size="sm"
            variant="relief-danger"
            v-b-modal.modal-add-blacklist
          >
            <feather-icon icon="PlusIcon" /> เพิ่ม Blacklist
          </b-button>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="filteredRow"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- table data -->
        <template slot="table-row" slot-scope="props">
          <!-- fullname -->
          <span v-if="props.column.field === 'full_name'" class="text-nowrap">
            <span class="text-nowrap">
              {{ props.row.first_name }} {{ props.row.last_name }} <br />
            </span>
          </span>

          <!-- data -->
          <span v-if="props.column.field === 'data'" class="text-nowrap">
            <span class="text-nowrap">
              <div
                class="d-flex justify-content-between"
                v-if="props.row.detail"
              >
                <span>รายละเอียด: </span>
                <span>{{ props.row.detail }}</span>
              </div>
              <div
                class="d-flex justify-content-between"
                v-if="props.row.phone"
              >
                <span>phone: </span>
                <span>{{ props.row.phone }}</span>
              </div>
              <div
                class="d-flex justify-content-between"
                v-if="props.row.email"
              >
                <span>email: </span>
                <span>{{ props.row.email }}</span>
              </div>
              <div
                class="d-flex justify-content-between"
                v-if="props.row.first_name_en || props.row.last_name_en"
              >
                <span>EN Name: </span>
                <span>{{ props.row.first_name_en }}</span>
                <span>{{ props.row.last_name_en }}</span>
              </div>
              <!-- <div v-for="(value, key) in props.row" :key="key">
                <div class="d-flex justify-content-between">
                  <span> {{ key }}</span>
                  <span>{{ value }} </span>
                </div>
              </div>
              <br /> -->
            </span>
          </span>

          <!-- action -->
          <span v-if="props.column.field === 'action'" class="text-nowrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="relief-warning"
              @click="$parent.editModal(props.row)"
              size="sm"
              class="mb-1"
            >
              Edit
            </b-button>
            <br />
            <confirm-delete
              @delete="Delete"
              :data="props.row._id"
            ></confirm-delete>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { BPagination, BFormSelect, BButton, BCard } from "bootstrap-vue";
import ConfirmDelete from "../../../components/ConfirmDeleteButton.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BCard,
    ConfirmDelete,
  },
  computed: {
    filteredRow() {
      if (!this.rows || !this.rows.length) return [];
      const excludeKeys = ["__v", "vgt_id", "originalIndex", "updatedAt"];
      return this.rows.map((row) => {
        return Object.keys(row)
          .filter((key) => !excludeKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = row[key];
            return obj;
          }, {});
      });
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 30,
      searchTerm: "",
      columns: [
        {
          label: "ชื่อ",
          field: "full_name",
        },
        {
          label: "ข้อมูล",
          field: "data",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
    };
  },
  created() {
    this.load_data();
  },
  methods: {
    load_data() {
      this.$http({
        method: "post",
        url: `/member/blacklist/filter`,
      }).then((x) => {
        this.rows = x.data.data;
      });
    },
    Delete(x) {
      if (x.confirm) {
        this.$http({
          method: "DELETE",
          url: `/member/blacklist/${x.data}`,
        }).then(async (x) => {
          await this.load_data();
        });
      }
    },
    editModal(row) {
      this.$bvModal.show("modal-edit-blacklist");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
