<template>
  <b-button variant="relief-danger" :size="size" @click="confirmDelete(data)">
    {{ title }}
  </b-button>
</template>
<script>
import { BButton } from "bootstrap-vue";
import { t } from "@/@core/libs/i18n/utils";

export default {
  components: {
    BButton,
  },
  props: {
    title: { default: "Delete" },
    data: null,
    size: { default: "sm" },
  },

  methods: {
    confirmDelete(x) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยันการลบ !!!", {
          title: "ยืนยันการลบ",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.$emit("delete", {
            confirm: value,
            data: x,
          });
        });
    },
  },
};
</script>
